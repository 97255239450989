import React, { useEffect, useState, useContext } from "react";
import { Link, navigate } from "gatsby";
import Skeleton from "react-loading-skeleton";
import sanitizeHtml from "sanitize-html";
import PageWrapper from "../components/PageWrapper";
import { AuthContext } from "../context/AuthContext";
import { fetchContractDetails, updateContract, updateJob } from "../api";
import notify from "../utils/notify";
import { sign_contract } from "../utils/utilities";
import { useWalletSelector } from "../context/WalletSelectorContext";
import { utils } from "near-api-js";

import iconD from "../assets/image/svg/icon-dolor.svg";

const ContractSigning = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState(null);
  const { auth } = useContext(AuthContext);
  const urlParams = new URLSearchParams(location.search);
  let transactionHashes = urlParams.get("transactionHashes");
  let errorMessage = urlParams.get("errorMessage");

  const { selector, accountId } = useWalletSelector()

  const contractSign = async (payload) => {
    try {
      const { missionId, talentId, jobId, job } = payload;

      const amountInYocto = utils.format.parseNearAmount(
        (job.storageCost + job.cost).toString()
      );

      const wallet = await selector.wallet()
      const response = await wallet
        .signAndSendTransaction({
          signerId: accountId,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "stake_mission",
                args: {
                  mission_id: missionId,
                  talent_id: talentId,
                  job_id: jobId,
                  job: {
                    storage_cost: job.storageCost.toString(),
                    cost: job.cost.toString(),
                    status: job.status,
                  },
                },
                gas: "300000000000000",
                deposit: amountInYocto
              },
            },
          ],
        })
      return response
    }
    catch (err) {
      console.log("Failed to sign contract");
      return false
    };
  }

  const getContractDetails = async (contractId) => {
    const details = await fetchContractDetails(contractId);
    setContract(details);
    setLoading(false);
  };

  const addTxhashToContract = async (id, data, txHash) => {
    try {
      const payload = {
        status: "ongoing",
        txHash: txHash,
      };
      await updateContract(id, payload);
      const { jobId } = data;
      await updateJob(jobId, "contract", { status: "staked", payment: "done" }); // Set corresponding job to `staked` and payment to `done`
      notify("Contract signed successfully", "success");
      localStorage.removeItem("contractId");
      localStorage.removeItem("contractData");
      await getContractDetails(id);
    } catch (error) {
      console.log(error.message);
      notify(error.message);
    }
  };

  const initiateSignContract = async (data) => {
    const response = await contractSign(data);
    notify("Contract Signing");
  };

  useEffect(() => {
    const contractId = localStorage.getItem("contractId");
    const contractData = localStorage.getItem("contractData");
    console.log(transactionHashes);
    // console.log("errorMessage: ", errorMessage);
    if (transactionHashes) {
      addTxhashToContract(contractId, contractData, transactionHashes);
    } else if (contractData) {
      initiateSignContract(JSON.parse(contractData));
    } else if (errorMessage) {
      notify(errorMessage);
      console.log(errorMessage);
      navigate(`/contract-details/${contract.id}`, { replace: true });
    }
    // console.log("else: ", errorMessage);
    // getContractDetails(contractId);
    return () => setLoading(true);
  }, []);

  const createMarkup = (html) => {
    return {
      __html: sanitizeHtml(html, {
        allowedAttributes: false,
        allowedTags: false,
      })
    };
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12">
          <div className="container">
            <div className="row justify-content-center">

              <div className="col-xl-10 col-lg-11 mt-4 ml-xxl-32 ml-xl-15 dark-mode-texts">
                <div className="mb-9">
                  {loading ? (
                    <Skeleton width={200} height={45} />
                  ) : (
                    <Link
                      to="#"
                      className="d-flex align-items-center ml-4"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/contract-details/${contract.id}`, { replace: true });
                      }}
                    >
                      {" "}
                      <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                      <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                        Back
                      </span>
                    </Link>
                  )}
                </div>
              </div>

              <div className="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
                <div className="bg-white rounded-4 border border-mercury shadow-9">
                  <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-8 border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="media align-items-center">
                          <div className="square-72 d-block mr-8">
                            {loading ? (
                              <Skeleton width={72} height={72} />
                            ) : (
                              <i className="fas fa-file-contract font-size-12 text-green"></i>
                            )}
                          </div>
                          <div>
                            <h3 className="font-size-6 mb-0">
                              {loading ? (
                                <Skeleton width={300} height={34} />
                              ) : `${contract?.jobTitle} Contract`}
                            </h3>
                            <span className="font-size-3 text-gray line-height-2">
                              {loading ? (
                                <Skeleton width={300} height={24} />
                              ) : contract?.client}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 text-right pt-7 pt-md-0 mt-md-n1">
                        <div className="media justify-content-md-end">
                          <p className="font-size-4 text-gray mb-0">
                            {loading ? (
                              <Skeleton width={300} height={24} />
                            ) : (
                              `${new Date(contract?.created.toDate()).toLocaleDateString()} ${new Date(contract?.created.toDate()).toLocaleTimeString()}`
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-9">
                      <div className="col-12">
                        <div className="card-btn-group">
                          {loading ? (
                            <Skeleton width={300} height={34} />
                          ) : (
                            <div className="d-flex align-items-center">
                              <i className={`fas ${contract?.status === "read" ? "fa-eye" : "fa-circle"} ${["active", "ongoing", "completed"].includes(contract.status) ? 'text-shamrock' : 'text-green'} mr-2`}></i>
                              <span className="font-size-3 text-gray line-height-2">
                                {contract?.status}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                    <div className="row mb-7">
                      <div className="col-md-4 mb-md-0 mb-6">
                        <div className="media justify-content-md-start">
                          <div className="image mr-5">
                            <img src={iconD} alt="currency icon" />
                          </div>
                          <p className="font-weight-semibold font-size-5 text-gray mb-0">
                            {loading ? (
                              <Skeleton width={50} height={24} />
                            ) : `${contract?.total.toFixed(2)} ${contract?.currency}`}
                          </p>
                        </div>
                      </div>
                      {auth?.talent?.account_id === contract?.talent ||
                        auth?.client?.account_id === contract?.client ||
                        auth?.admin ? (
                        <div className="col-md-4 pr-lg-0 pl-lg-10 mb-md-0 mb-6">
                          <div className="media justify-content-md-start">
                            <div className="image mr-5">
                              <i className="fas fa-user-tag font-size-5 text-green"></i>
                            </div>
                            <Link
                              to={`/candidate-profile/${contract?.talent}`}
                              state={{ id: contract?.talent }}
                              className="font-weight-semibold font-size-5 text-gray mb-0"
                            >
                              {loading ? (
                                <Skeleton width={200} height={24} />
                              ) : contract?.talent}
                            </Link>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 light-mode-texts">
                    <div className="row">
                      <div className="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
                        {/* <div className="mb-11">
                          <h6 className="font-size-5 text-black-2 font-weight-semibold">
                            Contract Description
                          </h6>
                          {loading ? (
                            <Skeleton width={600} height={600} />
                          ) : (
                            <div className="font-size-3 mb-7 job-description" dangerouslySetInnerHTML={createMarkup(contract?.description || "")} />
                          )}
                        </div> */}

                        <div className="mb-11">
                          {/* <hr /> */}
                          {loading ? (
                            <Skeleton height={24} containerClassName="w-100" className="w-100" />
                          ) : contract?.txHash ? (
                            <div>
                              <span className="font-size-4 text-gray font-weight-semibold line-height-2 line-height-2 mr-2">Transaction of NEAR:</span>
                              <a
                                href={`https://explorer.testnet.near.org/transactions/${contract?.txHash}`}
                                target="_blank"
                                rel="noreferrer"
                                className="font-size-4 text-gray line-height-2 line-height-2"
                              >
                                <span className="mr-2">{contract?.txHash}</span>
                                <i className="fa fa-external-link-alt"></i>
                              </a>
                            </div>
                          ) : null}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default ContractSigning;
